import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap';
import './CarouseSlide.css'
import CarouselCaption from './CarouselCaption'

class CarouselSlide extends Component{
    render() {
        return (
        <section className="carouselSlide">
            <Carousel>
            <Carousel.Item>
                <a href="/about_us">
                    <img
                    className="d-block w-100 bg1"
                    src="https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/CGSAALL.jpg"
                    alt="First slide"
                    />
                </a>
                <Carousel.Caption>
                    <CarouselCaption
                        header="我们是谁"
                        
                        desc="我们是南加州大学中国研究生学生会，简称USC-CGSA。我们的宗旨是为南加大的中国研究生，博士生，MBA 提供一个专注于就业，学术和社交相关的平台，并为广大留学生提供力所能及的留学问题，法律，手续方面的帮助。"
                    />
                
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/* <a href="/welfare"> */}
                    <img
                    className="d-block w-100 bg2"
                    src="https://usccgsa-web.s3.amazonaws.com/slider/2024sp_hiking.jpg"
                    alt="Second slide"
                    />
                {/* </a> */}
                <Carousel.Caption>
                    <CarouselCaption
                        header="2024 CGSA Hiking"
                        
                        desc="春日Hiking活动 在美丽的 Switzer Falls Trail 圆满落幕。 这次活动不仅是对身体的挑战，更是一场心灵的洗礼，让大家在自然的怀抱中找到内心的平静和快乐。"

                    />
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/* <a href="/welfare"> */}
                    <img
                    className="d-block w-100 bg2"
                    src="https://usccgsa-web.s3.amazonaws.com/slider/2024sp_network.png"
                    alt="Second slide"
                    />
                {/* </a> */}
                <Carousel.Caption>
                    <CarouselCaption
                        header="2024 CGSA 南加州职业发展与交流峰会"
                        
                        desc="利用USC的平台，将CGSA的职业发展部打造成南加州唯一的青年杰出人才、社会资源人脉、学生求职与创业分享交流平台"

                    />
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/* <a href="/welfare"> */}
                    <img
                    className="d-block w-100 bg2"
                    src="https://usccgsa-web.s3.amazonaws.com/slider/2024sp_cny.jpg"
                    alt="Second slide"
                    />
                {/* </a> */}
                <Carousel.Caption>
                    <CarouselCaption
                        header="2024 CGSA 春节庙会"
                        
                        desc="在这个特别的日子里，我们一起感受了浓浓的节日氛围，品尝了家乡的味道。更重要的是，我们共同收获了满满的快乐与回忆！"

                    />
                </Carousel.Caption>
            </Carousel.Item>
            </Carousel>
        </section>
            
        );
    }
}
export default CarouselSlide;
