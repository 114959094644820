import React, {useState, useEffect} from 'react'
import axios from 'axios'
import DeptTemplate from './DeptTemplate';
import { translate } from '../general/translate'


function Department() {
    const [depts, setDepts] = useState([])
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    useEffect(() => {
        axios.get(backendUrl + '/dept/all')
            .then(res => {
                setDepts(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [backendUrl])
    return (
        <div>
            <h2>{translate('部门介绍')}</h2>
             {depts.map((dept, index)=>{
                return (
                    <DeptTemplate
                        key={index}
                        index={index}
                        dept={dept.deptName}
                        deptEn={dept.deptNameEn}
                        keyword={dept.keyword}
                        keywordEn={dept.keywordEn}
                        detail={dept.detail.split('=>')}
                        detailEn={dept.detailEn.split('=>')}
                        img={dept.deptImg}
                    />
                )
            })}
        </div>
    )
}

export default Department
