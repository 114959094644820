import React from 'react'
import './Depttemplate.css'
import { Accordion, Card } from "react-bootstrap"
import useTranslate from '../customHook/useTranslate'

function DeptTemplate({ index,
    dept,
    deptEn,
    keyword,
    keywordEn,
    detail,
    detailEn,
    img }) {

    useTranslate();

    return (
        <div className="deptBody">
            <img src={img} alt="" />
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                        <h5 className="dept langBlock cn"> {dept} </h5>
                        <h5 className="dept langBlock en"> {deptEn} </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index}>
                        <Card.Body>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>关键词： {keyword}</strong></p>
                                    {detail.map((info,index) => (<p key={index}>{info}</p>))}
                                </div>
                            </div>
                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>Keyword： {keywordEn}</strong></p>
                                    {detailEn.map((info,index) => (<p key={index}>{info}</p>))}
                                </div>
                            </div>
                            <hr />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default DeptTemplate